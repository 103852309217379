.profile-header {
  position: relative;
  background: url("../../../images/bg-circles.png");
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: right;
}

.profile-header--wrapper > div {
  flex-basis: 50%;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .profile-header--wrapper > div {
    flex-basis: 100%;
  }
}

.profile-header--image {
  width: 270px;
  height: 270px;

  border-radius: 50%;
  z-index: 1;
}

.profile-header--image img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.profile--accessDescription:hover span {
  opacity: 1;
  pointer-events: all;
}

.profile--tooltip {
  width: 450px;
  transition: opacity 150ms ease-in-out;
}

@media only screen and (max-width: 767px) {
  .profile--tooltip {
    width: calc(100vw - 2em);
  }
}
