.jobsListGrid {
  display: grid;
  grid-template-columns: 0.5fr 4fr 0.5fr;
}

.gradientWrapper {
  padding: 2px;
  background-image: linear-gradient(90deg, #37b6bf99 0%, #e0a93e99 101.93%);
}

@media (min-width: 768px) {
  .jobListItemFixedHeight {
    min-height: 310px;
  }
}
